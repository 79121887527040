<template>
  <ez-info-box full-height slot="content">
    <div slot="image" class="error-image">
      <img src="@dmant/ez-lib/src/ability/assets/403.jpg" alt="Error 403">
    </div>
    <template slot="title">{{ $t("403.title") }}</template>
    <template slot="description">{{ $t("403.description") }}</template>
    <template slot="button">
      <ez-button
        v-if="isAuthorized"
        size="large"
        type="primary"
        @click.prevent="goToDashboard"
      >{{ $t("404.backButton") }}</ez-button>
      <ez-button
        v-else
        size="large"
        type="primary"
        @click.prevent="login"
      >{{ $t("404.loginButton") }}</ez-button>
    </template>
  </ez-info-box>
</template>

<script>
import { mapGetters } from 'vuex';
import { URL_FUNNELS, URL_COURSE } from '@dmant/ez-env-common';

export default {
  dependencies: ['USER_API'],
  name: 'EzPageForbidden403',
  created() {
    window.document.title = 'EZ Contact - Forbidden';
  },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/IS_LOGGED_IN',
    }),
  },
  methods: {
    login() {
      const { protocol, host } = window.location;
      this.USER_API.goToSsoPage(`${protocol}//${host}`);
    },
    goToDashboard() {
      if (this.$ability.can('view', 'Contacts') && this.$route.name !== 'dashboard') {
        this.$router.push({ path: '/' });
      } else {
        const URL = this.$ability.can('create', 'Funnels') ? URL_FUNNELS : URL_COURSE;
        window.location.assign(URL);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.error-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}

.error-image {
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 221px;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
body {
  background-color: #fff;
}
</style>
